export const male_names: string[] = [
  "Alaric",
  "Aldous",
  "Archibald",
  "Arne",
  "Arthur",
  "Bahram",
  "Bard",
  "Bartholomew",
  "Benedict",
  "Bertram",
  "Björn",
  "Burchard",
  "Cassian",
  "Cathasarch",
  "Charibert",
  "Conrad",
  "Crispin",
  "Cyprian",
  "Daegal",
  "Drogo",
  "Drustan",
  "Dustin",
  "Elric",
  "Emil",
  "Finnian",
  "Florian",
  "Galileo",
  "Gandalf",
  "Gerold",
  "Godfrey",
  "Godwin",
  "Gomes",
  "Gregory",
  "Grimwald",
  "Hamlin",
  "Hawk",
  "Hildebald",
  "Jeremiah",
  "Kazamir",
  "Kenric",
  "Lancaster",
  "Leif",
  "Leoric",
  "Lothar",
  "Lunden",
  "Maurin",
  "Morcant",
  "Neville",
  "Njal",
  "Odel",
  "Orvyn",
  "Osric",
  "Pascal",
  "Piers",
  "Randolf",
  "Ricard",
  "Svend",
  "Theodoric",
  "Torsten",
  "Wilkin",
  "Wolf",
  "Wymond",
  "Zemislav",
];

export const female_names: string[] = [
  "Adelaide",
  "Ameila",
  "Anastasia",
  "Anika",
  "Annora",
  "Avice",
  "Berenice",
  "Bodil",
  "Bogdana",
  "Brenna",
  "Brunhilda",
  "Clotilda",
  "Desislava",
  "Edme",
  "Ella",
  "Erika",
  "Genevieve",
  "Georgia",
  "Gunnora",
  "Hawise",
  "Helena",
  "Hildegund",
  "Luthera",
  "Martine",
  "Mathilda",
  "Maude",
  "Mirabel",
  "Odelgarde",
  "Olive",
  "Petra",
  "Regina",
  "Winifred",
  "Zelda",
];
